import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { BsSearch } from 'react-icons/bs';

import { useCallback, useRef, useEffect, useState } from 'react';
import { buildCssVar } from 'utils/style-override';

import { addQueryParamsToUrl, useGetPathWithQueryParams } from 'utils/article-link';
import { useFunnelData } from 'utils/funnel-data-context';
import { formatSlug } from 'utils/article-helpers';
import { FooterLinks } from 'constant';
import hamburgerMenu from '../public/hamburger-menu.svg';

import NavigationDropdown from './NavigationDropdown';
import NavigationLink from './NavigationLink';
import Image from './Image';
import Spinner from './Spinner.theme3';

import styles from './NavigationMenu.module.css';

const propTypes = {
  logo: PropTypes.object,
  footer: PropTypes.shape({
    backgroundColor: PropTypes.string,
    brandName: PropTypes.string,
    logo: PropTypes.object,
    textColor: PropTypes.string,
  }),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        icon: PropTypes.object,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
  menuCategories: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        name: PropTypes.string,
        tags: PropTypes.arrayOf(
          PropTypes.shape({
            item: PropTypes.shape({
              name: PropTypes.string,
            }),
          })
        ),
      }),
    })
  ),
  menuLinks: PropTypes.shape({
    contactUs: PropTypes.string,
    ourStory: PropTypes.string,
  }),
};

const defaultProps = {
  logo: null,
  footer: null,
  socialLinks: null,
  menuLinks: {
    contactUs: FooterLinks.CONTACT_US,
    ourStory: FooterLinks.OUR_STORY,
  },
  menuCategories: null,
};

function NavigationMenu({ logo, footer, menuLinks, socialLinks, menuCategories }) {
  const { funnelId, shopCategory } = useFunnelData();

  const router = useRouter();

  const { control, handleSubmit } = useForm();

  const [menuState, setMenuState] = useState({
    openExplore: false,
    openShops: false,
    openExploreMobile: false,
    openShopsMobile: false,
    openMobileDropdown: false,
  });

  const handleArticleClick = useCallback(() => {
    setMenuState({
      openExploreMobile: false,
      openShopsMobile: false,
      openMobileDropdown: false,
      openExplore: !menuState.openExplore,
      openShops: false,
    });
  }, [menuState]);

  const handleShopsClick = useCallback(() => {
    setMenuState({
      openExploreMobile: false,
      openShopsMobile: false,
      openMobileDropdown: false,
      openShops: !menuState.openShops,
      openExplore: false,
    });
  }, [menuState]);

  const closeDropdowns = useCallback(() => {
    setMenuState({
      openExplore: false,
      openShops: false,
      openExploreMobile: false,
      openShopsMobile: false,
      openMobileDropdown: false,
      openSearchBar: false,
    });
  }, []);

  const handleHamburgerMenuClick = useCallback(() => {
    setMenuState({
      openExplore: false,
      openShops: false,
      openExploreMobile: false,
      openShopsMobile: false,
      openMobileDropdown: !menuState.openMobileDropdown,
    });
  }, [menuState]);

  const handleMobileExplore = useCallback(() => {
    setMenuState({
      openExplore: false,
      openShops: false,
      openMobileDropdown: true,
      openExploreMobile: !menuState.openExploreMobile,
      openShopsMobile: false,
    });
  }, [menuState]);

  const handleMobileShops = useCallback(() => {
    setMenuState({
      openExplore: false,
      openShops: false,
      openMobileDropdown: true,
      openShopsMobile: !menuState.openShopsMobile,
      openExploreMobile: false,
    });
  }, [menuState]);

  const handleOnSearch = useCallback(() => {
    setMenuState({
      openExplore: false,
      openShops: false,
      openExploreMobile: false,
      openShopsMobile: false,
      openMobileDropdown: false,
      openSearchBar: !menuState.openSearchBar,
    });
  }, [menuState]);

  const [showSpinner, setShowSpinner] = useState(false);

  const handleOnSearchSubmit = data => {
    setShowSpinner(true);
    setMenuState({
      openExplore: false,
      openShops: false,
      openExploreMobile: false,
      openShopsMobile: false,
      openMobileDropdown: false,
      openSearchBar: false,
    });

    const searchQuery = formatSlug(data.searchInput);
    const url = `/search?query=${searchQuery}&fid=${funnelId}`;
    router.push(addQueryParamsToUrl(url, router.query));
  };

  const homePath = useGetPathWithQueryParams('/home');

  const LinksList = ({ categories }) => {
    return (
      <div className={styles.list_wrapper}>
        {categories.map(({ item }) => {
          return (
            <div key={item.name} className={styles.category_wrapper}>
              <h2>{item.name}</h2>
              {item.tags.map(tag => {
                return (
                  tag.item && (
                    <NavigationLink
                      key={tag.item.name}
                      slug={item.name}
                      path={tag.item.name}
                      onCloseDropdowns={closeDropdowns}
                    />
                  )
                );
              })}
              <NavigationLink
                slug={item.name}
                onCloseDropdowns={closeDropdowns}
                displayText={`All ${item.name.toLowerCase()}`}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const ShopCategoryComponent = ({ category }) => {
    return category.map(item => {
      return (
        <NavigationLink
          key={item}
          slug={'products'}
          path={item}
          onCloseDropdowns={closeDropdowns}
        />
      );
    });
  };

  const SearchBar = () => {
    return (
      <>
        <button className={styles.search_btn} onClick={handleOnSearch} aria-label="Search">
          <BsSearch />
        </button>
        {menuState.openSearchBar && (
          <NavigationDropdown socialLinks={socialLinks} {...footer}>
            <div className={styles.search_dropdown}>
              <h1>What are you looking for?</h1>
              <form onSubmit={handleSubmit(handleOnSearchSubmit)}>
                <Controller
                  name="searchInput"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <input placeholder={`Try 'Nutrition'`} {...field} />}
                />
                <button type="submit">Search</button>
              </form>
            </div>
          </NavigationDropdown>
        )}
      </>
    );
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setShowSpinner(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // cleanup function
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // If we click outside of the dropdown, close it
  const ref = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const elmClicked = event.target.classList?.[0];
      const closestParentDiv = event.target?.closest('div')?.classList?.[0];

      if (
        elmClicked?.startsWith('NavigationMenu') ||
        closestParentDiv?.includes('NavigationMenu') ||
        elmClicked === 'hamburger-menu'
      ) {
        return;
      }

      if (elmClicked === styles?.right_links.a) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        const anyOpen = Object.values(menuState).some(v => v === true);

        if (anyOpen) {
          closeDropdowns();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, menuState, closeDropdowns]);

  return (
    <>
      {showSpinner && <Spinner />}

      <div className={styles.container}>
        <style jsx>{`
          .root {
            ${buildCssVar(
              '--category-header-color',
              footer.backgroundColor,
              'var(--default-background-color)'
            )}
            ${buildCssVar(
              '--footer-background-color',
              footer.backgroundColor,
              'var(--default-background-color)'
            )}
            ${buildCssVar('--footer-text-color', footer.textColor, 'var(--text-color-dark)')}
          }
        `}</style>
        <div className={styles.wrapper}>
          <button className={styles.hamburger_menu} onClick={handleHamburgerMenuClick}>
            <Image src={hamburgerMenu} alt="Menu" className="hamburger-menu" />
          </button>
          {menuState.openMobileDropdown && (
            <NavigationDropdown logo={footer.logo} socialLinks={socialLinks}>
              <div className={styles.mobile_dropdown}>
                <DropdownItem
                  name="Explore"
                  toggle={handleMobileExplore}
                  category={menuState.openExploreMobile}
                />
                {menuState.openExploreMobile && <LinksList categories={menuCategories} />}
                <DropdownItem
                  toggle={handleMobileShops}
                  name="Shop"
                  category={menuState.openShopsMobile}
                />
                {menuState.openShopsMobile && (
                  <div className={styles.shop_list_wrapper}>
                    <h2>Categories</h2>
                    <ShopCategoryComponent category={shopCategory} />
                    <NavigationLink
                      slug="products"
                      onCloseDropdowns={closeDropdowns}
                      displayText="All products"
                    />
                  </div>
                )}
                {menuLinks.contactUs && (
                  <Link href={addQueryParamsToUrl(menuLinks.contactUs, router.query)}>Contact</Link>
                )}
                {menuLinks.ourStory && (
                  <Link href={addQueryParamsToUrl(menuLinks.ourStory, router.query)}>
                    Our Story
                  </Link>
                )}
              </div>
            </NavigationDropdown>
          )}
          <div className={styles.left_links}>
            <DropdownItem
              toggle={handleArticleClick}
              name="Explore"
              category={menuState.openExplore}
            />
            <DropdownItem toggle={handleShopsClick} name="Shop" category={menuState.openShops} />
          </div>
          <div className={styles.logo_wrapper}>
            <Link className={styles.logo_btn} href={homePath}>
              <Image src={logo} alt={'Logo'} />
            </Link>
          </div>
          <div className={styles.mobile_search}>
            <SearchBar />
          </div>
          <div className={styles.right_links}>
            {menuLinks.contactUs && (
              <Link href={addQueryParamsToUrl(menuLinks.contactUs, router.query)}>Contact</Link>
            )}
            {menuLinks.ourStory && (
              <Link href={addQueryParamsToUrl(menuLinks.ourStory, router.query)}>Our Story</Link>
            )}
            <SearchBar />
          </div>
        </div>
        <div ref={ref}>
          {(menuState.openExplore || menuState.openShops) && (
            <>
              {menuState.openExplore && (
                <NavigationDropdown socialLinks={socialLinks} logo={footer.logo}>
                  <LinksList categories={menuCategories} />
                </NavigationDropdown>
              )}
              {menuState.openShops && (
                <NavigationDropdown socialLinks={socialLinks} logo={footer.logo}>
                  <div className={styles.shop_list_wrapper}>
                    <h2>Categories</h2>
                    <ShopCategoryComponent category={shopCategory} />
                    <NavigationLink
                      slug="products"
                      onCloseDropdowns={closeDropdowns}
                      displayText="All products"
                    />
                  </div>
                </NavigationDropdown>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const DropdownItem = ({ toggle, name, category }) => {
  return (
    <button className={styles.arrow_p} onClick={toggle}>
      <span className={styles.link_p}>{name}</span>
      <span className={`${styles.link_arrow} ${category ? styles.arrow_up : styles.arrow_down}`}>
        &#8963;
      </span>
    </button>
  );
};

NavigationMenu.propTypes = propTypes;
NavigationMenu.defaultProps = defaultProps;

export default NavigationMenu;
