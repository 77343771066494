import React from 'react';
import styles from './Spinner.theme3.module.css';

const Spinner = () => (
  <div className={styles.overlay}>
    <style jsx global>{`
      body {
        overflow: hidden;
      }
    `}</style>
    <div className={styles.loader}></div>

    <div className={`${styles.loader_section} ${styles.section_left}`}></div>
    <div className={`${styles.loader_section} ${styles.section_right}`}></div>
  </div>
);

export default Spinner;
