import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { buildCssVar } from 'utils/style-override';

import Image from './Image';

import styles from './NavigationDropdown.module.css';

const propTypes = {
  children: PropTypes.node,
  logo: PropTypes.object,
  socialLinks: PropTypes.array,
  backgroundcolor: PropTypes.string,
  textColor: PropTypes.string,
};

const defaultProps = {
  children: null,
  logo: null,
  socialLinks: null,
  backgroundcolor: null,
  textColor: null,
};

const NavigationDropdown = ({ children, logo, socialLinks, backgroundcolor, textColor }) => {
  return (
    <div className={`root ${styles.menu_dropdown}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, '#ffffff')}
          ${buildCssVar('--background-color', backgroundcolor, '#121212')}
        }
      `}</style>

      {children}
      <div className={`${styles.dropdown_footer} ${!logo ? styles.right_aligned : ''}`}>
        {logo && (
          <div className={styles.logo_footer}>
            <Image src={logo} alt="Logo" />
          </div>
        )}
        <div className={styles.social_links}>
          <p>Follow us on</p>
          {!!socialLinks &&
            socialLinks.map(({ item }) => (
              <Link href={item.url} key={item.url} legacyBehavior>
                <a target="_blank" rel="noreferrer">
                  <Image
                    src={item.icon}
                    alt={item.name}
                    width={20}
                    height={20}
                    placeholder="blur"
                  />
                </a>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

NavigationDropdown.propTypes = propTypes;
NavigationDropdown.defaultProps = defaultProps;

export default NavigationDropdown;
